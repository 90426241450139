<template>
  <div>
    <!-- 头 -->
    <div class="part0">
      <img src="@/image/index/planning_00.png" style="width: 100%;">
      <div class="part0_title flex_center">
        <div>{{$t('planning.title')}}</div>
      </div>
    </div>
    <!-- 段落1 -->
    <div class="part1" v-for="(item,index) in $t('planning.part1')">
      <div class="y_center" style="position: relative;">
        <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
        <div class="title">{{item.title}}</div>
      </div>
      <div class="part1_text">{{item.text}}</div>
    </div>
    <!-- 段落2 -->
    <div style="margin-top:30px;">
      <div style="position: relative;margin-bottom:20px;">
        <img src="@/image/index/planning_01.png" style="width: 100%;margin: 20px 0;">
        <div class="banner flex_center" style="color:#1CC6BC;z-index:3;">
          <div>{{$t('planning.part2.title')}}</div>
        </div>
        <div class="banner flex_center" style="color:#ffffff;z-index:2;padding:3px 0 0 3px;">
          <div>{{$t('planning.part2.title')}}</div>
        </div>
      </div>
      <div style="padding: 0 20px;">
        <div class="text" style="margin-bottom: 30px;">{{$t('planning.part2.text')}}</div>
        <div class="y_center" style="position: relative;">
          <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
          <div class="title">{{$t('planning.part2.title2')}}</div>
        </div>
        <div class="text" style="margin-bottom: 30px;" v-for="(item,index) in $t('planning.part2.textArr')">{{item}}</div>
      </div>
    </div>
    <!-- 段落3 -->
    <div class="part3">
      <div class="y_center" style="position: relative;">
        <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
        <div class="title">{{$t('planning.part3.title')}}</div>
      </div>
      <div class="part3_title">{{$t('planning.part3.title2')}}</div>
      <div class="text" style="margin-bottom: 25px;" v-for="(item,index) in $t('planning.part3.textArr')"><span style="color: #1CC6BC;">{{item.title}}</span>{{item.text}}</div>
      <div class="part3_box">
        <div class="flex_row" style="align-items: flex-start;" v-for="(item,index) in $t('planning.part3.textArr2')">
          <p />
          <div class="text" style="color: #1CC6BC;width: 0;flex: 1;margin-bottom: 10px;">{{item}}</div>
        </div>
      </div>
    </div>
    <!-- 段落4 -->
    <div style="margin-top:20px;padding:0 20px;">
      <div class="y_center" style="position: relative;">
        <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
        <div class="title">{{$t('planning.part4.title')}}</div>
      </div>
      <div class="text" style="margin-bottom: 25px;"  v-for="(item,index) in $t('planning.part4.textArr')">{{item}}</div>
    </div>
    <!-- 段落5 -->
    <div style="margin-top:20px;padding:0 20px;">
      <div class="y_center" style="position: relative;">
        <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
        <div class="title">{{$t('planning.part5.title')}}</div>
      </div>
      <div class="text" style="margin-bottom: 25px;"  v-for="(item,index) in $t('planning.part5.textArr')">{{item}}</div>
      <div class="part_title">{{$t('planning.part5.title2')}}</div>
      <div class="text" style="margin-bottom: 25px;">{{$t('planning.part5.text')}}</div>
      <div class="text" style="margin-bottom: 25px;">{{$t('planning.part5.text2')}}</div>
    </div>
    <!-- 段落6 -->
    <div style="margin-top:20px;padding:0 20px;">
      <div class="y_center" style="position: relative;">
        <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
        <div class="title">{{$t('planning.part6.title')}}</div>
      </div>
      <div class="text" style="margin-bottom: 25px;" v-for="(item,index) in $t('planning.part6.textArr')"><span style="color: #1CC6BC;">{{item.title}}</span>{{item.text}}</div>
    </div>
    <!-- 段落7 -->
    <div class="part3">
      <div class="y_center" style="position: relative;">
        <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
        <div class="title">{{$t('planning.part7.title')}}</div>
      </div>
      <div class="part3_box">
        <div class="flex_row" style="align-items: flex-start;margin-bottom:20px;" v-for="(item,index) in $t('planning.part7.textArr')">
          <p />
          <div class="text" style="color: #1CC6BC;width: 0;flex: 1;margin-bottom: 10px;">{{item}}</div>
        </div>
      </div>
      <div class="text" style="margin-bottom: 25px;" v-for="(item,index) in $t('planning.part7.textArr2')">{{item}}</div>
    </div>
    <!-- 段落8 -->
    <div style="position: relative;margin-bottom:20px;">
      <img src="@/image/index/planning_02.png" style="width: 100%;margin: 20px 0;">
      <div class="banner flex_center" style="color:#1CC6BC;z-index:3;">
        <div>{{$t('planning.part8.title')}}</div>
      </div>
      <div class="banner flex_center" style="color:#ffffff;z-index:2;padding:3px 0 0 3px;">
        <div>{{$t('planning.part8.title')}}</div>
      </div>
    </div>
    <div class="text" style="margin-bottom: 20px;padding: 0 20px;" v-for="(item,index) in $t('planning.part8.textArr')"><span style="color: #1CC6BC;">{{item.title}}</span>{{item.text}}</div>
    <!-- 段落9 -->
    <div style="position: relative;margin-bottom:20px;">
      <img src="@/image/index/planning_03.png" style="width: 100%;margin: 20px 0;">
      <div class="banner flex_center" style="color:#1CC6BC;z-index:3;margin:0 40px;">
        <div>{{$t('planning.part9.title')}}</div>
      </div>
      <div class="banner flex_center" style="color:#ffffff;z-index:2;margin:0 40px;padding:3px 0 0 3px;">
        <div>{{$t('planning.part9.title')}}</div>
      </div>
    </div>
    <div class="text" style="margin-bottom: 20px;padding: 0 20px;">{{$t('planning.part9.text')}}</div>
    <div class="y_center" style="position: relative;">
      <img src="@/image/index/biaoti_11.png" style="height:80px;margin:20px 0">
      <div class="title">{{$t('planning.part9.title2')}}</div>
    </div>
    <div style="padding: 0 20px;" v-for="(item,index) in $t('planning.part9.textArr')">
      <div class="part_title" style="margin: 10px 0;">{{item.title}}</div>
      <div class="text" style="margin-bottom: 20px;">{{item.text}}</div>
    </div>
    <!-- 段落10 -->
    <div style="position: relative;margin:40px 0;">
      <img src="@/image/index/planning_04.png" style="width: 100%;margin: 20px 0;">
      <div class="banner flex_center" style="color:#1CC6BC;z-index:3;margin:0 40px;">
        <div>{{$t('planning.part10.title')}}</div>
      </div>
      <div class="banner flex_center" style="color:#ffffff;z-index:2;margin:0 40px;padding:3px 0 0 3px;">
        <div>{{$t('planning.part10.title')}}</div>
      </div>
    </div>
    <div class="text" style="margin-bottom: 20px;padding: 0 20px;" v-for="(item,index) in $t('planning.part10.textArr')"><span style="color: #1CC6BC;font-weight: bold;">{{item.title}}</span>{{item.text}}</div>
  </div>
</template>

<script>
export default {
  name: "index",
  components: {},
  data() {
    return {
      
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      
    },
    
  },
};
</script>
<style lang="scss" scoped>
.text{
  color: #ffffff;
  font-size: calc(var(--text) - 2px);
  line-height: 20px;
}
.common_text{
  font-size: calc(var(--text) - 2px);
}
.banner{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
  font-size: calc(var(--title) + 4px);
  font-weight: bold;
  text-align: center;
}
.title{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-60%) translateX(-50%);
  margin: auto;
  color: #1CC6BC;
  font-weight: bold;
  font-size: calc(var(--title) + 5px);
  width: 100%;
  text-align: center;
}
.part_title{
  font-size: var(--title);
  color: #1CC6BC;
  margin:30px 0 20px 0;
  font-weight: bold;
}
.part0{
  position: relative;
  .part0_title{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    color: #1CC6BC;
    text-shadow: 0 1px 1px #123; 
    font-size: 40px;
    font-weight: bold;
    text-align: center;
    padding: 0 20px;
    width: 100%;
  }
}
.part1{
  .part1_text{
    color: #ffffff;
    padding: 0 40px;
    font-size: calc(var(--text) + 2px);
  }
}
.part3{
  padding: 0 20px;
  .part3_title{
    color: #1CC6BC;
    font-weight: bold;
    font-size: var(--title);
    margin: 20px 0;
  }
  .part3_box{
    background: rgba(18, 48, 182, 0.3);
    border-radius: 5px;
    padding: 20px 10px 0;
    p{
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #1CC6BC;
      margin: 5px 10px 0 0;
    }
  }
}

</style>